import React, { useState, useEffect } from "react";

const Principle = () => {

  const [principleData, setPrincipleData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://cms.maitretech.com/naveen-vidya/items/principle_message?fields=*.*"
        );
        const data = await response.json();
        if (data?.data?.length > 0) {
          setPrincipleData(data.data[0]); // Assuming you're fetching just the first item
        } else {
          setError("No data found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An error occurred while fetching the data.");
      } finally {
        setIsLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, []); // Empty dependency array to run useEffect only once on component mount

  // Render loading or error states
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }


  return (
    <>
      <div className="principal">
        <div className="principal-row">
          <div className="principal-col">
            {principleData?.principle_image?.data?.full_url && (
              <img
                src={principleData.principle_image.data.full_url.replace(
                  "http://",
                  "https://"
                )}
                alt="Principal"
              />
            )}
            <h5>{principleData?.principle_name || "N/A"}</h5>
          </div>
          <div className="principal-col">
            <h2>Principal Message</h2>
            <h6
              dangerouslySetInnerHTML={{
                __html: principleData?.principle_message || "No message available."
              }}
            />

          </div>
        </div>
      </div>
    </>
  );
};

export default Principle;
