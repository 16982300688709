import React from "react";
import Banner from "./Banner";
import AdmissionBanner from "./AdmissionBanner";

// import ReactCardSlider from 'react-card-slider-component';
// import { Carousel } from 'react-carousel-minimal';
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";

const Home = () => {


  return (
    <>
      <AdmissionBanner />
      <Banner />
      <div className="new-div">
        <div className="content_box">
          <div className="first_box bxx">
            <div className="bx1">
              <p>About</p>
              <h1>Naveen Senior Secondary School, Sehore, Bhopal</h1>
            </div>
            <div className="image_boxAb img-school">
              <img src="./img/homeschool.jpeg" alt="" />
            </div>

            <div className="para-content">
              <p>
                Naveen Senior Secondary School is based in city of lake
                Bhopal. Naveen Senior Secondary School is recognized as a
                developmentally enriching learning space, making learning fun
                for children. Naveen Senior Secondary School was founded
                and established in June 2004.
                <Link to="/ourSchool">read more</Link>
              </p>
            </div>
          </div>
          <div className="second_box bxx">
            <div className="bx1">
              <p>Message</p>
              <h1>Director</h1>
            </div>
            <div className="image_boxAb img-principle">
              <img src="./img/dir.jpeg" alt="" />
            </div>
            <div className="para-content">
              <p>
                Dear Parent & Students, It gives me great pleasure to welcome
                you to Naveen Senior Secondary School. The school is
                committed to inculcating in all our students; strong ethical
                valued of integrity respect.
                <Link to="/director"> read more</Link>
              </p>
            </div>
          </div>
          <div className="second_box bxx">
            <div className="bx1">
              <p>Message</p>
              <h1>Principal</h1>
            </div>
            <div className="image_boxAb img-principle">
              <img src="./img/pricihome.jpeg" alt="" />
            </div>
            <div className="para-content">
              <p>
                Welcome to Naveen Senior Secondary School, a home built on
                decades of value imbued traditions. As educators, we understand
                that children learn more from what you are and what you do than
                what you say. <Link to="/principal">read more</Link>
              </p>
            </div>
          </div>
          {/* <div className="third_box bxx">
          <div className="bx1">
            <p>Latest From</p>
            <h1>Notice Board</h1>
          </div>

          <div className="image_boxAbc">
            <div className="animatinct" style={{ padding: "5px" }}>
              <p>1. Class Prep Admission Guideline 2023-24</p>
              <p>2. School Transport Arrangement</p>
              <p>3. School Transport Arrangement</p>
              <p>4. School Transport Arrangement</p>
              <p>5. School Transport Arrangement</p>
            </div>
          </div>
        </div> */}
        </div>

        <div className="third_content_box">
          <div className="academic_planner">
            <div className="accontent">
              <p>Academic Planner</p>
            </div>
            <div className="imgggbox1">
              <div className="igg"></div>
            </div>
          </div>
          <div className="academic_planner">
            <div className="accontent">
              <p>Class Room</p>
            </div>
            <div className="imgggbox1 imgggbox12">
              <div className="igg"></div>
            </div>
          </div>
          <div className="academic_planner">
            <div className="accontent">
              <p>Republic Day</p>
            </div>
            <div className="imgggbox1 imgggbox13">
              <div className="igg igg1">
                <img src="./img/rep.jpeg" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="fourth_content_box">
          <div className="fouthmain">
            <div className="fourthOne">
              <div className="ffone">
                <h3>Photo Gallery</h3>
              </div>
              <div className="ffslider">
                <Carousel className="abcd">
                  <Carousel.Item className="jhk">
                    <img
                      className="d-block"
                      src="./img/g1.jpeg"
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item className="jhk">
                    <img
                      className="d-block"
                      src="./img/g2.jpeg"
                      alt="Second slide"
                    />
                  </Carousel.Item>

                  <Carousel.Item className="jhk">
                    <img
                      className="d-block"
                      src="./img/g3.jpeg"
                      alt="Third slide"
                    />
                  </Carousel.Item>

                  <Carousel.Item className="jhk">
                    <img
                      className="d-block"
                      src="./img/g4.jpeg"
                      alt="Third slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item className="jhk">
                    <img
                      className="d-block"
                      src="./img/g5.jpeg"
                      alt="Third slide"
                    />
                  </Carousel.Item>

                  <Carousel.Item className="jhk">
                    <img
                      className="d-block"
                      src="./img/g6.jpeg"
                      alt="Third slide"
                    />
                  </Carousel.Item>

                  <Carousel.Item className="jhk">
                    <img
                      className="d-block"
                      src="./img/g7.jpeg"
                      alt="Third slide"
                    />
                  </Carousel.Item>

                  <Carousel.Item className="jhk">
                    <img
                      className="d-block"
                      src="./img/g8.jpeg"
                      alt="Third slide"
                    />
                  </Carousel.Item>

                  {/* <Carousel.Item className="jhk">
                  <img
                    className="d-block"
                    src="./img/g9.jpeg"
                    alt="Third slide"
                  />
                </Carousel.Item> */}
                  <Carousel.Item className="jhk">
                    <img
                      className="d-block"
                      src="./img/g10.jpeg"
                      alt="Third slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="ffbutton">
                <Link to="/gallery">
                  <button>View More</button>
                </Link>
              </div>
            </div>
            <div className="fourthOne">
              <div className="ffone">
                <h3>Video Gallery</h3>
              </div>
              <div className="ffslider">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/eGTLc-GSyzA"
                  title="Youtube Video Player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="ffbutton">
                <a
                  href="https://www.youtube.com/embed/eGTLc-GSyzA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button>View More</button>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="cardSliderimage new-div" >
        <div className="latest">
          <p className="lnews">Latest From</p>
          <h2 className="newsevent">News & Events</h2>
        </div>

     
        
        <ReactCardSlider slides={slides} />
        <Carousel className="slidercarouse">
          <Carousel.Item className="kppp">
            <img
              className="d-block"
              src="./images/cr1.jpg"
              alt="Second slide"
            />
            <h4>Merry Christmas</h4>
            <h6>25 December</h6>
          </Carousel.Item>
          <Carousel.Item className="kppp">
            <img
              className="d-block"
              src="./images/new1.jpg"
              alt="First slide"
            />
            <h4>Happy New Year</h4>
            <h6>1 January</h6>
          </Carousel.Item>
          <Carousel.Item className="kppp">
            <img className="d-block" src="./images/mk.jpg" alt="Third slide" />
            <h4>Makar Sankranti</h4>
            <h6>15 January </h6>
          </Carousel.Item>
          <Carousel.Item className="kppp">
            <img className="d-block" src="./images/rep.jpg" alt="Third slide" />
            <h4>Republic Day</h4>
            <h6>26 January</h6>
          </Carousel.Item>
        </Carousel>
      </div> */}
      </div>
    </>
  );
};

export default Home;
