import React, { useState, useEffect } from "react";
import "./css/Director.css"

const Director = () => {


  const [directorData, setDirectorData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://cms.maitretech.com/naveen-vidya/items/director_message?fields=*.*"
        );
        const data = await response.json();
        if (data?.data?.length > 0) {
          setDirectorData(data.data[0]); // Assuming you're fetching just the first item
        } else {
          setError("No data found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An error occurred while fetching the data.");
      } finally {
        setIsLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, []); // Empty dependency array to run useEffect only once on component mount

  // Render loading or error states
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }




  return (
    <>
      <div className="director-sid">
        <div className="director-sid-row">
          <div className="director-sid-col">
            {directorData?.director_image?.data?.full_url && (
              <img
                src={directorData.director_image.data.full_url.replace(
                  "http://",
                  "https://"
                )}
                alt="Principal"
              />
            )}
            <h4>{directorData?.director_name || "N/A"}</h4>
          </div>
          <div className="director-sid-col">
            <h2>Director Message</h2>
            <h6
              dangerouslySetInnerHTML={{
                __html: directorData?.director_message || "No message available."
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Director;
