import React from "react";

const TopNav = () => {
  let flag = 0;

  const openmenu = () => {
    let menubar = document.querySelector(".menu-bar");
    if (flag === 0) {
      menubar.style.display = "block";
      flag = 1;
    } else {
      menubar.style.display = "none";
      flag = 0;
    }
  };

  return (
    <>
      <div className="toptop">
        <div className="tonavbar1">
          <img src="./img/Schoollogo.png" alt="" />
          <div className="imp">
            <h1 style={{ color: "#ffff" }}>
              <span id="firsth">N</span>AVEEN <span id="firsth">S</span>ENIOR{" "}
              <span id="firsth">S</span>ECONDARY {" "}
              <span id="firsth">S</span>CHOOL
            </h1 >
            <p style={{ color: "#ffff" }}>Learning today. Leading tomorrow. Inspired Forever.</p>
          </div>
        </div>
        <div className="menuimageicon">
          <i class="ri-menu-line" onClick={() => openmenu()}></i>
        </div>
      </div>
    </>
  );
};

export default TopNav;
